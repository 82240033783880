import { getData, postInternalData, postExternalData } from "./GetData";
import Qs from "qs";

export default {
  getCompanyloanProducts(queryString: object, token: string | undefined) {
    const url = "/company_loan";

    const paramsSerializer = (params: object) =>
      Qs.stringify(params, { arrayFormat: "brackets" });
    const config = {
      method: "GET",
      params: queryString,
      headers: {
        Authorization: "Bearer " + token,
      },
      paramsSerializer: {
        serialize: paramsSerializer,
      },
    };

    return getData(url, config);
  },

  async sendWidgetData(apiUrl: string, config: any) {
    return postInternalData(apiUrl, config);
  },
};
