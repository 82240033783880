import type { sfConfType } from "@/types/sfConfType";

interface LocalConfigType {
  sendUrlPath: string;
  sendFormat: string;
  sendMethod: string;
}

export default class ToUrlParams {
  dataToSend: any;
  config: LocalConfigType;

  constructor(sfConfig: sfConfType) {
    this.config = {
      sendUrlPath: sfConfig.sendUrlPath,
      sendFormat: sfConfig.sendFormat,
      sendMethod: sfConfig.sendMethod,
    };
  }

  addData(dataToSend: any) {
    this.dataToSend = dataToSend;
  }

  send() {
    const url = new URL(this.config.sendUrlPath);

    switch (this.config.sendFormat) {
      case "json":
        // TODO: code block
        break;
      case "string":
        url.searchParams.append(
          "prodId",
          String(this.dataToSend.productItem.id)
        );
        url.searchParams.append(
          "investmentValue",
          String(this.dataToSend.filters.investmentValue)
        );
        url.searchParams.append(
          "creditValue",
          String(this.dataToSend.filters.creditValue)
        );
        url.searchParams.append(
          "secureValue",
          String(this.dataToSend.filters.secureValue)
        );
        url.searchParams.append(
          "creditPeriod",
          String(this.dataToSend.filters.creditPeriod)
        );
        url.searchParams.append(
          "creditClientAge",
          String(this.dataToSend.filters.creditClientAge)
        );
        url.searchParams.append(
          "companyStartUp",
          encodeURI(String(this.dataToSend.filters.companyStartUp))
        );
        url.searchParams.append(
          "economicActivity",
          String(this.dataToSend.filters.economicActivity)
        );
        url.searchParams.append(
          "taxationForm",
          String(this.dataToSend.filters.taxationForm)
        );
        url.searchParams.append(
          "productTypes",
          String(this.dataToSend.filters.productTypes)
        );
        break;
      case "array":
        // TODO: code block
        break;
      case "object":
        // TODO: code block
        break;
      default:
      // TODO: code block
    }

    if (this.config.sendMethod === "GET") {
      window.location.href = url.toString();
    }
  }
}
