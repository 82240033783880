type queryObjType = {
  investmentValue?: number;
  creditValue?: number;
  secureValue?: number;
  creditPeriod?: number;
  creditClientAge?: number;
  companyStartUp?: string;
  productTypes?: any;
  securities?: any;
  economicActivity?: string;
  taxationForm?: string;
  fixedInterestRate?: number;
  variableInterestRate?: number;
  periodicalInterestRate?: number;
  eco?: number;
  bestBankOffer?: number;
  indexWibor?: number;
  indexWiron?: number;
  clientInternal?: number;
  clientExternal?: number;

  securityType?: string;
  currencies?: any;
};

export function formToQuery(
  investmentVal: number,
  creditVal: number,
  secureVal: number,
  periodVal: number,
  ageVal: number,
  companyStartUpVal: string,
  economicActivityVal: string,
  taxationFormVal: string,
  productTypesVal: string
) {
  const queryObj: queryObjType = {};
  // pseudo DTO
  queryObj.investmentValue = Number(investmentVal);
  queryObj.creditValue = Number(creditVal);
  queryObj.secureValue = Number(secureVal);
  queryObj.creditPeriod = Number(periodVal);
  queryObj.creditClientAge = Number(ageVal);

  queryObj.companyStartUp = "";
  if (companyStartUpVal !== "") {
    queryObj.companyStartUp = companyStartUpVal;
  }

  if (productTypesVal !== "all") {
    queryObj.productTypes = {};
    queryObj.productTypes[productTypesVal] = productTypesVal;
  }

  queryObj.economicActivity = economicActivityVal;
  queryObj.taxationForm = taxationFormVal;

  queryObj.securities = {};
  queryObj.securities["secured"] = "secured";
  queryObj.securities["unsecured"] = "unsecured";

  queryObj.fixedInterestRate = 1;
  queryObj.variableInterestRate = 1;

  // queryObj.periodicalInterestRate = 0;
  // queryObj.eco = 0;
  queryObj.bestBankOffer = 1;

  queryObj.indexWibor = 1;
  queryObj.indexWiron = 1;
  queryObj.clientInternal = 0;
  queryObj.clientExternal = 0;

  queryObj.securityType = "";

  queryObj.currencies = {};
  queryObj.currencies[0] = 1;

  return queryObj;
}
