
import { defineComponent } from "vue";
import { showAsCurrency } from "@/services/showAsCurrency";
import { showAsPercent2 } from "@/services/showAsPercent";

export default defineComponent({
  name: "ProductItem",
  props: ["item", "resultButton", "productTypes"],
  components: {},
  emits: ["showContact"],
  setup: function (props, ctx) {
    // console.log("item==", props.item);
    const showContact = () => {
      ctx.emit("showContact", props.item);
    };

    return {
      showAsCurrency,
      showAsPercent2,
      showContact,
    };
  },
});
