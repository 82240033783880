
import { computed, defineComponent, onMounted, ref, toRaw, watch } from "vue";

export default defineComponent({
  name: "InputPlainText",
  props: {
    inputValue: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  emits: ["changedVal"],
  setup: function (props, ctx) {
    const localInputValue = computed({
      get: () => props.inputValue,
      set: (newValue) => {
        ctx.emit("changedVal", newValue);
      },
    });

    return {
      localInputValue,
    };
  },
});
