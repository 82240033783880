
import {
  Ref,
  computed,
  defineComponent,
  getCurrentInstance,
  nextTick,
  onMounted,
  ref,
  toRaw,
} from "vue";

import type { CustomWindow } from "@/types/window";
import type { sfConfType } from "@/types/sfConfType";
import type { ProductItemType } from "@/types/product";
import { showAsCurrency } from "@/services/showAsCurrency";
import { formToQuery } from "../services/DataToParams";
import FetchData from "../services/FetchDataService";
import { ExporterFactory } from "../services/exporterFactory";

import ComboInputSlider from "./ui/ComboInputSlider.vue";
import InputPlainText from "./ui/InputPlainText.vue";
import InputSelect from "./ui/InputSelect.vue";
import ProductItem from "./ProductItem.vue";
import ContactForm from "./ContactForm.vue";

declare let window: CustomWindow;

export default defineComponent({
  name: "WebWidget",
  props: {
    title: {
      type: String,
      required: true,
      default: "",
    },
    contactTitle: {
      type: String,
      required: true,
      default: "",
    },
    filterButton: {
      type: String,
      required: true,
      default: "",
    },
    resultButton: {
      type: String,
      required: true,
      default: "",
    },
    contactFormButton: {
      type: String,
      required: true,
      default: "",
    },
    campaignId: {
      type: String,
      required: true,
      default: "",
    },
  },
  components: {
    ComboInputSlider,
    InputPlainText,
    InputSelect,
    ProductItem,
    ContactForm,
  },
  setup: function (props) {
    const sfConfig: Ref<sfConfType | null> = ref(null);
    const investmentVal = ref(400000);
    const creditVal = ref(200000);
    const secureVal = ref(400000);

    const periodVal = ref(60);
    const ageVal = ref(30);
    const companyStartUpVal = ref("");

    const economicActivityVal = ref("jdg");
    const economicActivityOptions = [
      { id: "jdg", title: "JDG" },
      { id: "generalPartnership", title: "Spółka jawna" },
      { id: "civil", title: "Spółka cywilna" },
      { id: "limitedPartnership", title: "Spółka komandytowa" },
      { id: "partnership", title: "Spółka partnerska" },
      { id: "zoo", title: "Spółka z o.o" },
      { id: "jointStockCompany", title: "Spółka akcyjna" },
      { id: "agriculturalActivity", title: "Działalność rolnicza" },
      { id: "housingAssociation", title: "Wspólnota mieszkaniowa" },
    ];

    const taxationFormVal = ref("kpir");
    const taxationFormOptions = [
      { id: "kpir", title: "KPiR" },
      { id: "lumpSum", title: "Ryczałt" },
      { id: "taxCard", title: "Karta podatkowa" },
      { id: "fullAccountancy", title: "Pełna księgowość" },
      { id: "agriculturalTax", title: "Podatek rolny" },
      { id: "repairFund", title: "Fundusz remontowy" },
    ];

    const productTypesVal = ref("all");
    const productTypesValOnSend = ref("all");
    const productTypesOptions = [
      { id: "all", title: "Wybierz" },
      { id: "account_limit", title: "Limit w koncie" },
      { id: "installment_loan", title: "Pożyczka ratalna" },
      { id: "investment_loan", title: "Kredyt inwestycyjny" },
      { id: "developer_loan", title: "Kredyt deweloper" },
      { id: "professional", title: "Profesjonalista" },
      { id: "agro_loan", title: "Kredyt agro" },
      { id: "revolving_loan", title: "Kredyt obrotowy" },
      { id: "eu_loan", title: "Konsolidacja" },
    ];
    const modal = ref(false);

    const isLoading = ref(false);
    const showResults = ref(false);
    const resultList = ref<ProductItemType[]>([]);

    const dataToSend = ref({
      campaignId: "",
      user: {
        name: "",
        city: "",
        phone: "",
        email: "",
        message: "",
      },
      filters: {
        investmentValue: 0,
        creditValue: 0,
        secureValue: 0,
        creditPeriod: 0,
        creditClientAge: 0,
        companyStartUp: "",
        economicActivity: "",
        taxationForm: "",
        productTypes: "",
      },
      productItem: {
        id: 0,
        title: "",
        bank: "",
      },
    });

    const showContact = (item: ProductItemType) => {
      if (sfConfig.value === null) {
        console.log("brak pliku konfiguracyjnego");
        return false;
      }

      dataToSend.value.productItem = {
        id: item.id,
        title: item.title,
        bank: item.bank.name,
      };

      if (
        sfConfig.value.sendType === "internalSF" ||
        sfConfig.value.sendType === "internalForm"
      ) {
        modal.value = !modal.value;
      } else {
        const externalExport = ExporterFactory.createExporter(sfConfig.value);
        externalExport.addData(dataToSend.value);
        externalExport.send();
      }
    };

    const closeContact = () => {
      modal.value = !modal.value;
    };

    const instance = getCurrentInstance();
    const sanitize = instance?.appContext.config.globalProperties.$sanitize;

    const changedInvestmentVal = (value: number) => {
      investmentVal.value = value;
    };
    const changedCreditVal = (value: number) => {
      creditVal.value = value;
    };
    const changedSecureVal = (value: number) => {
      secureVal.value = value;
    };
    const changedPeriodVal = (value: number) => {
      periodVal.value = value;
    };
    const changedAgeVal = (value: number) => {
      ageVal.value = value;
    };
    const changedCompanyStartUpVal = (value: string) => {
      companyStartUpVal.value = value;
    };
    const changedEconomicActivityVal = (value: string) => {
      economicActivityVal.value = value;
    };
    const changedTaxationFormVal = (value: string) => {
      taxationFormVal.value = value;
    };
    const changedProductTypesVal = (value: string) => {
      productTypesVal.value = value;
    };

    const companyloanCheckClick = (e: MouseEvent) => {
      if (e.target !== null) {
        (e.target as HTMLElement).blur();
      }
      companyloanCheck();
    };

    const companyloanCheck = () => {
      isLoading.value = true;

      nextTick(() => {
        const queryString = formToQuery(
          investmentVal.value,
          creditVal.value,
          secureVal.value,
          periodVal.value,
          ageVal.value,
          companyStartUpVal.value,
          economicActivityVal.value,
          taxationFormVal.value,
          productTypesVal.value
        );

        const result = FetchData.getCompanyloanProducts(
          queryString,
          sfConfig.value?.readToken
        );

        result.then((resObj) => {
          if (resObj.status === 200) {
            productTypesValOnSend.value = productTypesVal.value;
            resultList.value = resObj.data;
            // console.log("odebrane===", resObj);

            dataToSend.value.filters = {
              investmentValue: toRaw(investmentVal.value),
              creditValue: toRaw(creditVal.value),
              secureValue: toRaw(secureVal.value),
              creditPeriod: toRaw(periodVal.value),
              creditClientAge: toRaw(ageVal.value),
              companyStartUp: toRaw(companyStartUpVal.value),
              economicActivity: toRaw(economicActivityVal.value),
              taxationForm: toRaw(taxationFormVal.value),
              productTypes: toRaw(productTypesVal.value),
            };

            showResults.value = true;
            isLoading.value = false;
          }
        });
      });
    };

    const resultInfo = computed(() => {
      return sanitize(sfConfig.value?.resultInfo);
    });

    onMounted(() => {
      const widgetConfig = window.widgetConfig;
      if (typeof widgetConfig !== "undefined") {
        sfConfig.value = widgetConfig;
      }

      dataToSend.value.campaignId = props.campaignId;
    });

    return {
      sfConfig,
      investmentVal,
      creditVal,
      secureVal,
      periodVal,
      ageVal,
      companyStartUpVal,
      economicActivityVal,
      economicActivityOptions,
      taxationFormVal,
      taxationFormOptions,
      productTypesVal,
      productTypesValOnSend,
      productTypesOptions,
      changedInvestmentVal,
      changedCreditVal,
      changedSecureVal,
      changedPeriodVal,
      changedAgeVal,
      changedCompanyStartUpVal,
      changedEconomicActivityVal,
      changedTaxationFormVal,
      changedProductTypesVal,
      companyloanCheckClick,
      companyloanCheck,
      resultList,
      showResults,
      isLoading,
      showContact,
      closeContact,
      modal,
      showAsCurrency,

      dataToSend,

      resultInfo,
    };
  },
});
